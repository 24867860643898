import React from 'react';
import './Board.css';
import DiceArea from '../DiceArea/DiceArea';
import getCheckers from '../getCheckers/getCheckers';
import Triangle from './Triangle/Triangle';

const board = (props) => {

    const {
        triangleClick,
        gameStatus,
        isP1,
        handleStart,
        startDice,
        isYourTurn,
        p1IsNext,
        isLongBackgammon,
    } = props;

    // console.log('props ', props);

    let leftDiceArea = null;
    let rigthDiceArea = null;
    if (gameStatus > 10 && gameStatus <= 59) {
        
        // leftDiceArea = props.p1IsNext ? <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus}/> : '';
        // rigthDiceArea = props.p1IsNext ? '' : <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus} />;
        let content = <DiceArea dice={props.dice} clicked={props.rollDice} gameStatus={props.gameStatus} color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'}/>;

        leftDiceArea = !isP1 ? !p1IsNext ? content : '' : p1IsNext ? content : '';
        rigthDiceArea = !isP1 ? p1IsNext ? content : '' : !p1IsNext ? content : '';

        

        if (!props.dice[0]) {
            if (isP1) {
                if (p1IsNext) {
                    leftDiceArea = '';  
                    rigthDiceArea = '';
                }
            } else {
                if (!p1IsNext) {
                    leftDiceArea = '';  
                    rigthDiceArea = '';
                }
            }
        }

        if (props.gameStatus === 50 ) { //No moves available
            if (isP1) {
                if (props.p1IsNext) {
                    rigthDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                } else {
                    leftDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                }    
            } else {
                if (props.p1IsNext) {
                    leftDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                    // rigthDiceArea = content;
                } else {
                    rigthDiceArea =  !isYourTurn ? <DiceArea color={isP1 ? !p1IsNext ? 'black' : 'white' : p1IsNext ? 'white' : 'black'} dice={[0]} clicked={props.rollDice} gameStatus={51}/> : ''; //Status 51 creates a escapes the no moves available button
                }
    
            }
        } 

        // leftDiceArea =  <DiceArea color={'white'} dice={[2, 2, 2, 2]} clicked={props.rollDice} gameStatus={50}/> ;
        // if (!props.dice[0]) {
        //     if (!props.isP1) {
        //         leftDiceArea = '';                
        //     } else {
        //         rigthDiceArea = '';                
        //     }
        // }

        // if (!props.dice[0]) {
        //     if (props.isP1) {
        //         leftDiceArea = '';                
        //     } else {
        //         rigthDiceArea = '';                
        //     }
        // }

    }


    if (gameStatus === 80) {
        // leftDiceArea = <DiceArea dice={startDice.p1} color='black' clicked={isP1 ? handleStart : null } gameStatus={80}/>;
        // rigthDiceArea = <DiceArea dice={startDice.p2} color='white' clicked={!isP1 ? handleStart : null} gameStatus={80}/>;
        // leftDiceArea = <DiceArea dice={[4]} color='white' clicked={null } gameStatus={80}/>;

        leftDiceArea = null;
        //<DiceArea dice={startDice.p1} color='white' clicked={handleStart } gameStatus={80}/>;
        //<DiceArea dice={[0]} color='black' gameStatus={80}/>;

        rigthDiceArea = <DiceArea dice={isP1 ? startDice.p1 : startDice.p2} color='black' clicked={handleStart} gameStatus={80}/>;

        if (isP1) {
            leftDiceArea = null;
            if (startDice.p2 && startDice.p2[0] > 0) {
                leftDiceArea = <DiceArea dice={startDice.p2} color='black'  gameStatus={80}/>;
            }

            rigthDiceArea = <DiceArea dice={startDice.p1} color='white' clicked={handleStart} gameStatus={80}/>;
        } else {
            leftDiceArea = null;
            if (startDice.p1 && startDice.p1[0] > 0) {
                leftDiceArea = <DiceArea dice={startDice.p1} color='white'  gameStatus={80}/>;
            }
            rigthDiceArea = <DiceArea dice={startDice.p2} color='black' clicked={handleStart} gameStatus={80}/>;

        }
    }

    const renderSection = (from, to, position) => {
        const step = from > to ? -1 : 1;
        const numbers = [];
        // for(let n = from; n += step; step < 0 ? n < to : n > to) {
        //     numbers.push(n);
        // }
        let n = from;
        while(step < 0 ? n >= to : n <= to) {
            numbers.push(n);
            n += step; 
        } 

        return numbers.map((n, index) => {
            // console.log('color ', index % 2 == 0 ? '1': '2')
            return (
                <Triangle
                    color={'2'} 
                    key={n}
                    position={position}
                    canMove={props.points[n].canMove}
                    canReceive={props.points[n].canReceive}
                    triangleClick={triangleClick}                        
                    number={n}
                >
                    {/* {n} */}
                    {getCheckers(props.points[n].player, props.points[n].checkers, "board", props.points[n].canMove)}
                </Triangle>
            )
        })
    }


    if (isLongBackgammon) {
        if (!isP1) {
            return (
                <div id="board" >
                    <div id="leftSide" >
                        <div className="blocksUp">
                            {renderSection(11, 6, 'top')}
                        </div>
         
                        {leftDiceArea}
         
                        <div className="blocksDown">
                            {renderSection(12, 17, 'bottom')}
                        </div>
                    </div>
                    
                    {props.children}
        
                    <div id="rightSide" >
                        <div className="blocksUp">
                            {renderSection(5, 0, 'top')}
                        </div>
                        
                        {rigthDiceArea}
        
                        <div className="blocksDown">
                            {renderSection(18, 23, 'bottom')}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div id="board" >
                    <div id="leftSide" >
                        <div className="blocksUp">
                            {renderSection(23, 18, 'top')} 

                        </div>
         
                        {leftDiceArea}
         
                        <div className="blocksDown">
                            
                        {renderSection(0, 5, 'bottom')}
                           
                        </div>
                    </div>
                    
                    {props.children}
        
                    <div id="rightSide" >
                        <div className="blocksUp">
                            {renderSection(17, 12, 'top')}
                        </div>
                        
                        {rigthDiceArea}
        
                        <div className="blocksDown">
                            {renderSection(6, 11, 'bottom')}
                        </div>
                    </div>
                </div>
            )
        }
    }

    if (!isP1) {
        return (
            <div id="board" >
                <div id="leftSide" >
                    <div className="blocksUp">
                        {renderSection(11, 6, 'top')}
                    </div>
     
                    {leftDiceArea}
     
                    <div className="blocksDown">
                        {renderSection(12, 17, 'bottom')}
                    </div>
                </div>
                
                {props.children}
    
                <div id="rightSide" >
                    <div className="blocksUp">
                        {renderSection(5, 0, 'top')}
                    </div>
                    
                    {rigthDiceArea}
    
                    <div className="blocksDown">
                        {renderSection(18, 23, 'bottom')}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="board" >
                <div id="leftSide" >
                    <div className="blocksUp">
                        {renderSection(12, 17, 'top')}
                    </div>
     
                    {leftDiceArea}
     
                    <div className="blocksDown">
                        {renderSection(11, 6, 'bottom')}
                    </div>
                </div>
                
                {props.children}
    
                <div id="rightSide" >
                    <div className="blocksUp">
                        {renderSection(18, 23, 'top')}
                    </div>
                    
                    {rigthDiceArea}
    
                    <div className="blocksDown">
                        {renderSection(5, 0, 'bottom')}
                    </div>
                </div>
            </div>
        )
    }
}

export default board;